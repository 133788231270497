import * as React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { InternalLink } from '../components/Link'
import TextWrapper from '../components/TextWrapper'
import Paragraph from '../components/Paragraph'
import { List, ListItem } from '../components/List'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <TextWrapper>
      <Paragraph>
        We couldn't find the page you were looking for. This is either because:
      </Paragraph>
      <List>
        <ListItem>
          There is an error in the URL entered into your web browser. Please
          check the URL and try again.
        </ListItem>
        <ListItem>
          The page you are looking for has been moved or deleted.
        </ListItem>
      </List>
      You can return to our homepage by{' '}
      <InternalLink to='/'>clicking here</InternalLink>.
    </TextWrapper>
  </Layout>
)

export default NotFoundPage
